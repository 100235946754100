<script>
  import { state, seed } from "./state.js";

  export let index;
  export let show;
  export let no;

  function playMusic(file) {
    var music = new Audio();

    music.pause();
    music = new Audio(file);
    music.play();
    music = null;
  }
</script>

<div
  on:mouseover={playMusic(`audio/${no ? "N" : "Y"}${(index % 11) + 1}.mp3`)}
  class="btn {show ? '' : 'btn-hidden'}"
  class:no
  on:click={() => {
    playMusic(`audio/Magic.mp3`);
    state.set(2);
    // seed.set(99);
  }}
>
  🕊
</div>

<style lang="scss">:global(.btn) {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
  font-size: 48px;
  color: #873030;
  border: #873030 3px solid;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  border-radius: 420px;
  padding: 0px 16px 8px 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 53px;
  text-align: center;
  transition: all 0.75s linear; }
  :global(.btn):hover {
    transition: none;
    background-color: #873030;
    color: grey; }
  :global(.btn).no {
    color: black;
    border: black 3px solid; }
    :global(.btn).no:hover {
      color: white;
      background-color: black; }

.btn-hidden {
  opacity: 0; }</style>
